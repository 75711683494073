<template lang="pug">
v-card(v-if="Object.keys(sailorDocument).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ $t(`${Object.keys(sailorDocument.behavior)[0]}-${type}`).toUpperCase() }}
      span(v-if="checkAccess('backOffice')") (ID: {{ sailorDocument.id }})
    ControlButtons( :controlButtons="controlButtons").justify-end
  v-card-text
    v-divider
    Info(v-if="sailorDocument.behavior.viewInfoBlock" :sailorDocument="sailorDocument")

    Edit(v-else-if="sailorDocument.behavior.viewEditBlock" :sailorDocument="sailorDocument")

    ViewPhotoList(
      v-else-if="sailorDocument.behavior?.viewFilesBlock"
      :files="sailorDocument.photo"
      :sailorDocument="sailorDocument"
      :documentType="type"
      :deleteFile="removeFileChangeFullName"
      :editFile="updateFileChangeFullName"
      isShowEdit
      )
</template>

<script>
import { viewDetailedComponent, back, deleteConfirmation } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapActions } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'SailorFullNameChangesDocument',
  components: {
    Info: () => import('@/views/Sailor/SailorFullNameChanges/Info.vue'),
    Edit: () => import('@/views/Sailor/SailorFullNameChanges/Form.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  data () {
    return {
      type: 'sailorFullNameChanges',
      viewDetailedComponent,
      deleteConfirmation,
      checkAccess,
      controlButtons: [
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info'
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditBlock'),
          checkAccess: () => checkAccess('sailorFullNameChanges', 'edit', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.edit'
        },
        {
          id: 3,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewFilesBlock'),
          checkAccess: () => checkAccess('sailorFullNameChanges', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files'
        },
        {
          id: 4,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('sailorFullNameChanges', 'delete', this.sailorDocument),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete'
        },
        {
          id: 5,
          name: 'mdi-close',
          action: () => back('passports-changes'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      id: (state) => state.sailor.sailorId,
      labelName: (state) => (state.main.lang === 'en' ? 'name_eng' : 'name_ukr'),
      sailorDocument: state => state.sailor.sailorFullNameChangesById
    })

  },
  mounted () {
    this.getFullNameChangesById({ ...this.$route.params })
  },
  methods: {
    ...mapActions(['getFullNameChangesById', 'removeChangedFullNameById', 'removeFileChangeFullName',
      'updateFileChangeFullName']),
    deleteDocument () {
      deleteConfirmation(this).then(async (confirmation) => {
        if (confirmation) {
          const response = await this.removeChangedFullNameById({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response.code)) {
            this.$notification.success('deletedDocument')
            back('passports-changes')
          } else {
            this.$notification.error('cantDeleteDoc')
          }
        }
      })
    }
  }
}
</script>
